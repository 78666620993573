export const SET_DATA = "data/SET_DATA" as const;

export const setData = (data: any): Reducer<typeof SET_DATA> => ({
  type: SET_DATA,
  payload: { data },
});

type DataAction = ReturnType<typeof setData>;

const initialState: Data = {
  articles: [],
  banners: [],
  benefits: [],
  branches: [],
  faqs: [],
  holidays: [],
  memberships: [],
  promotions: [],
  services: [],
  starterTypes: [],
  garage: {
    blog: "",
    breakTime: "",
    businessLicence: "",
    company: "",
    email: { business: "", pr: "", support: "" },
    facebook: "",
    instagram: "",
    kakao: "",
    naver: "",
    news: "",
    newsletter: "",
    operationHour: "",
    privacyPolicyUri: "",
    representative: "",
    termUri: "",
  },
};

const dataReducer = (
  state: typeof initialState = initialState,
  { type, payload }: DataAction
): typeof initialState => {
  switch (type) {
    case SET_DATA:
      return { ...state, ...payload.data };
    default:
      return state;
  }
};

export default dataReducer;
