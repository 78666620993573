import Search from "@components/Search";
import { firestore } from "@utils/firebase";
import { PageHeader, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";

export default function Partnership() {
  const [partnerships, setPartnerships] = useState<any>([]);
  const [results, setResults] = useState<any>([]);
  console.log(results);
  const onSearch = (query: string) => {
    const _results = partnerships.filter((item: object) =>
      JSON.stringify(item).includes(query)
    );
    setResults(_results);
  };

  const getData = async () => {
    const { docs } = await firestore()
      .collection("/partnership")
      .orderBy("createAt", "desc")
      .get();
    const data = docs.map((doc) => doc.data());
    setPartnerships(data);
    setResults(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns: ColumnsType<any> = [
    { title: "이름", key: "name", dataIndex: "name" },
    { title: "이메일", key: "email", dataIndex: "email" },
    {
      title: "연락처",
      key: "phone",
      dataIndex: "phone",
      render: (_, { phone, contact }) => phone || contact,
    },
    { title: "주소", key: "address", dataIndex: "address" },
    { title: "상세주소", key: "addressDetail", dataIndex: "addressDetail" },
    { title: "전용면적", key: "area", dataIndex: "area" },
    { title: "방문경로", key: "path", dataIndex: "path" },
    { title: "내용", key: "comment", dataIndex: "comment" },
    { title: "생성시간", key: "createAt", dataIndex: "createAt" },
  ];

  return (
    <>
      <PageHeader title="파트너십" />
      <Table
        style={{ wordBreak: "keep-all" }}
        title={() => <Search onSearch={onSearch} />}
        dataSource={results}
        columns={columns}
        rowKey={"createAt"}
      />
    </>
  );
}
