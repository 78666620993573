import Input from "@components/Input";
import { Button, Collapse, Image } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { setData } from "reducers/data";

export default function Benefit() {
  const dispatch = useDispatch();
  const services = useSelector((state: RootState) => state.data.services);
  const [active, setActive] = useState<any>([]);

  const onChangeService = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    dispatch(
      setData({
        services: [
          ...services.slice(0, index),
          { ...services[index], [name]: value },
          ...services.slice(index + 1),
        ],
      })
    );
  };

  const onClickDelete = (index: number) => {
    dispatch(
      setData({
        services: [...services.slice(0, index), ...services.slice(index + 1)],
      })
    );
  };

  const onClickAdd = () => {
    dispatch(
      setData({
        services: [
          ...services,
          {
            serviceId: "",
            name: "새 서비스",
            imageUri: "",
            value: 10,
          },
        ],
      })
    );
    setActive(services.length);
  };

  return (
    <div>
      <Collapse activeKey={active} onChange={(key) => setActive(key)}>
        {services.map((s, index) => (
          <Collapse.Panel header={s.name || " "} key={index}>
            <Input
              label="아이디"
              hint="중복 X"
              inputProps={{
                name: "serviceId",
                value: s.serviceId,
                onChange: (e) => onChangeService(e, index),
              }}
            />
            <Input
              label="이름"
              inputProps={{
                name: "name",
                value: s.name,
                onChange: (e) => onChangeService(e, index),
              }}
            />
            <Input
              label="정렬값"
              inputProps={{
                name: "value",
                value: s.value,
                onChange: (e) => onChangeService(e, index),
              }}
            />
            <Input
              label="이미지"
              inputProps={{
                name: "imageUri",
                value: s.imageUri,
                onChange: (e) => onChangeService(e, index),
              }}
            />
            <Image
              src={
                s.imageUri.startsWith("http")
                  ? s.imageUri
                  : `https://thegarage.kr${s.imageUri}`
              }
            />
            <br />
            <br />
            <Button type="dashed" onClick={() => onClickDelete(index)}>
              서비스 제거
            </Button>
          </Collapse.Panel>
        ))}
      </Collapse>
      <br />
      <br />
      <Button onClick={onClickAdd}>서비스 추가</Button>
    </div>
  );
}
