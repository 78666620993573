import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

console.log("process.env.NODE_ENV : ", process.env.NODE_ENV);

const firebaseConfig = process.env.NODE_ENV === "production" ? {
  apiKey: 'AIzaSyBBoHpTNLEk8nGzJgiESjKqSb_S5YC0DlQ',
  authDomain: 'garage-fd828.firebaseapp.com',
  projectId: 'garage-fd828',
  storageBucket: 'garage-fd828.appspot.com',
  messagingSenderId: '148828623367',
  appId: '1:148828623367:web:dcebcf6f6d460fad9950a2',
  measurementId: 'G-1VTEFB7WZP',
} : {
  apiKey: "AIzaSyCKtOwU98C4i-6qwcSKyWktPfDfA5TgqSI",
  authDomain: "exalted-tape-398211.firebaseapp.com",
  projectId: "exalted-tape-398211",
  storageBucket: "exalted-tape-398211.appspot.com",
  messagingSenderId: "907236845865",
  appId: "1:907236845865:web:2d0aca775b5cf26a87ef38",
  measurementId: "G-7NEN12EWD6"
}

export const initialize = () => {
  if (firebase.apps.length) return;
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().settings({ experimentalForceLongPolling: true });
};

export const firestore = () => {
  initialize();
  return firebase.firestore();
};
export const storage = () => {
  initialize();
  return firebase.storage();
};

export default firebase;
