import Input from "@components/Input";
import Textarea from "@components/Textarea";
import { Collapse } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import update from "immutability-helper";
import { RootState } from "reducers";
import { setData } from "reducers/data";

export default function Membership() {
  const dispatch = useDispatch();
  const memberships = useSelector((state: RootState) =>
    state.data.memberships.sort((a, b) => b.value - a.value)
  );
  const [active, setActive] = useState<any>([]);

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    dispatch(
      setData({
        memberships: update(memberships, {
          [index]: { $set: { ...memberships[index], [name]: value } },
        }),
      })
    );
  };
  return (
    <div>
      <Collapse activeKey={active} onChange={(key) => setActive(key)}>
        {memberships.map((m, index) => (
          <Collapse.Panel header={m.name} key={index}>
            <Input
              label="아이디"
              hint="다른 멤버십 아이디와 겹치면 X"
              inputProps={{
                name: "membershipId",
                value: m.membershipId,
                onChange: (e) => onChange(e, index),
              }}
            />
            <Input
              label="이름"
              hint=""
              inputProps={{
                name: "name",
                value: m.name,
                onChange: (e) => onChange(e, index),
              }}
            />
            <Input
              label="영어이름"
              hint=""
              inputProps={{
                name: "englishName",
                value: m.englishName,
                onChange: (e) => onChange(e, index),
              }}
            />
            <Textarea
              label="설명"
              hint=""
              textareaProps={{
                name: "description",
                value: m.description,
                onChange: (e) => onChange(e, index),
              }}
            />
            <Input
              label="설명2"
              hint=""
              inputProps={{
                name: "detail",
                value: m.detail,
                onChange: (e) => onChange(e, index),
              }}
            />
            <Textarea
              label="랜딩페이지 설명"
              hint="랜딩페이지에 노출 될 설명 문구"
              textareaProps={{
                name: "landingDescription",
                value: m.landingDescription,
                onChange: (e) => onChange(e, index),
              }}
            />
            <Input
              label="이미지 주소"
              hint=""
              inputProps={{
                name: "imageUri",
                value: m.imageUri,
                onChange: (e) => onChange(e, index),
              }}
            />
            <Input
              label="링크버튼 텍스트"
              hint=""
              inputProps={{
                name: "linkText",
                value: m.linkText,
                onChange: (e) => onChange(e, index),
              }}
            />
            <Input
              label="링크"
              hint=""
              inputProps={{
                name: "link",
                value: m.link,
                onChange: (e) => onChange(e, index),
              }}
            />
            <Input
              label="정렬값"
              hint=""
              inputProps={{
                name: "value",
                value: m.value,
                onChange: (e) => onChange(e, index),
              }}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
}
