import Search from "@components/Search";
import { firestore } from "@utils/firebase";
import { PageHeader, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";

export default function Newsletter() {
  const [newsletters, setNewsletters] = useState<any>([]);
  const [results, setResults] = useState<any>([]);

  const onSearch = (query: string) => {
    const _results = newsletters.filter((item: object) =>
      JSON.stringify(item).includes(query)
    );
    setResults(_results);
  };

  const getData = async () => {
    const { docs } = await firestore()
      .collection("/newsletter")
      .orderBy("createAt", "desc")
      .get();
    const data = docs.map((doc) => doc.data());
    setNewsletters(data);
    setResults(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns: ColumnsType<any> = [
    { title: "이름", key: "name", dataIndex: "name" },
    { title: "이메일", key: "email", dataIndex: "email" },
    { title: "생성시간", key: "createAt", dataIndex: "createAt" },
  ];

  return (
    <>
      <PageHeader title="뉴스레터" />
      <Table
        style={{ wordBreak: "keep-all" }}
        title={() => <Search onSearch={onSearch} />}
        dataSource={results}
        columns={columns}
        rowKey={"createAt"}
      />
    </>
  );
}
