export const BankLabels: Record<string, string> = {
  "39": "경남",
  "34": "광주",
  "12": "단위농협",
  "32": "부산",
  "45": "새마을",
  "64": "산림",
  "88": "신한",
  "48": "신협",
  "27": "씨티",
  "20": "우리",
  "71": "우체국",
  "50": "저축",
  "37": "전북",
  "35": "제주",
  "90": "카카오",
  "92": "토스",
  "81": "하나",
  "54": "HSBC",
  "03": "기업",
  "06": "국민",
  "31": "대구",
  "02": "산업",
  "11": "농협",
  "23": "SC제일",
  "07": "수협",
  none: "-",
};
