import Input from "@components/Input";
import Textarea from "@components/Textarea";
import { Row, Col, Image, Collapse } from "antd";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { setData } from "reducers/data";

export default function Article() {
  const dispatch = useDispatch();
  const articles = useSelector((state: RootState) => state.data.articles);
  const [active, setActive] = useState<any>([]);

  const onChangeArticle = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    dispatch(
      setData({
        articles: [
          ...articles.slice(0, index),
          { ...articles[index], [name]: value },
          ...articles.slice(index + 1),
        ],
      })
    );
  };
  const imageUri = useCallback(
    (uri) => (uri.startsWith("http") ? uri : `https://thegarage.kr${uri}`),
    []
  );
  return (
    <div>
      <Collapse activeKey={active} onChange={(key) => setActive(key)}>
        {articles.map((article, index) => {
          return (
            <Collapse.Panel header={article.content} key={article.articleId}>
              <Input
                label="아이디"
                hint="다른거랑 겹치면 X"
                inputProps={{
                  name: "articleId",
                  value: article.articleId,
                  onChange: (e) => onChangeArticle(e, index),
                }}
              />
              <Row gutter={10}>
                <Col key={index} span={6}>
                  <Image src={imageUri(article.imageUri)} />
                  <Input
                    inputProps={{
                      name: "imageUri",
                      value: article.imageUri,
                      onChange: (e) => onChangeArticle(e, index),
                    }}
                  />
                </Col>
              </Row>
              <Input
                label="이미지 텍스트"
                hint="이미지 가운데 들어갈 텍스트"
                inputProps={{
                  name: "imageText",
                  value: article.imageText,
                  onChange: (e) => onChangeArticle(e, index),
                }}
              />
              <Input
                label="링크"
                hint="클릭시 연결"
                inputProps={{
                  name: "link",
                  value: article.link,
                  onChange: (e) => onChangeArticle(e, index),
                }}
              />
              <Textarea
                label="내용"
                hint=""
                textareaProps={{
                  name: "content",
                  value: article.content,
                  onChange: (e) => onChangeArticle(e, index),
                  style: { minHeight: "40px" },
                }}
              />
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </div>
  );
}
