import { firestore } from "@utils/firebase";
import { useEffect, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setData } from "reducers/data";

export default function Loader() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { replace } = useHistory();

  const data = async () => {
    const _data = await firestore().collection("settings").doc("data").get();
    dispatch(setData(_data.data()));
  };

  useLayoutEffect(() => {
    const AUTH = "/auth";
    if (location.pathname === AUTH) return;
    const key = window.localStorage.getItem("key");
    if (key !== "rkfkwl578") replace(AUTH);
  }, [location]);

  useEffect(() => {
    data();
  }, []);

  return <div />;
}
