import React from "react";

import { Provider } from "react-redux";
import store from "./store";

import Contact from "pages/Contact";
import Newsletter from "pages/Newsletter";
import Partnership from "pages/Partnership";
import Space from "pages/Space";
import Starter from "pages/Starter";
import Tour from "pages/Tour";
import Index from "pages";

import { BrowserRouter, Switch, Route } from "react-router-dom";
import Loader from "@components/Loader";
import Nav from "@components/Nav";
import Auth from "pages/Auth";
import Data from "pages/Data";

import "./styles/quill.scss";

export default function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Nav />
        <main>
          <Switch>
            <Route path="/newsletter" component={Newsletter} />
            <Route path="/partnership" component={Partnership} />
            <Route path="/space" component={Space} />
            <Route path="/starter" component={Starter} />
            <Route path="/tour" component={Tour} />
            <Route path="/contact" component={Contact} />
            <Route path="/auth" component={Auth} />
            <Route path="/data" component={Data} />
            <Route exact path="/" component={Index} />
          </Switch>
        </main>
        <Loader />
      </BrowserRouter>
    </Provider>
  );
}
