export type FormItemProps = {
  label?: string;
  hint?: string;
  noBottomSpace?: boolean;
  children: JSX.Element;
};

export default function FormItem({
  label = "",
  hint = "",
  noBottomSpace = false,
  children,
}: FormItemProps) {
  return (
    <div style={{ marginBottom: !noBottomSpace ? "20px" : "0px" }}>
      <div style={{ fontSize: "14px" }}>{label}</div>
      <div>{children}</div>
      <div style={{ color: "gray", fontSize: "12px" }}>{hint}</div>
    </div>
  );
}
