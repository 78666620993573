import { Input } from "antd";
import React, { useState } from "react";

type Props = {
  onSearch?: (query: string) => void;
};

export default function Search({ onSearch }: Props): JSX.Element {
  const [query, setQuery] = useState("");

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!onSearch) return;
    if (e.key === "Enter") onSearch(query);
  };
  return (
    <div>
      <Input
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        placeholder={"검색어 입력 후 Enter"}
        onKeyPress={onKeyPress}
      />
    </div>
  );
}
