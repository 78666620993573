import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function Index() {
  const { push } = useHistory();
  useEffect(() => {
    push('/contact');
  }, []);
  return <div>index</div>;
}
