import React from "react";
import { Input as AntdInput } from "antd";
import FormItem from "@components/FormItem";
import { TextAreaProps } from "antd/lib/input";

type Props = {
  textareaProps?: TextAreaProps;
  label?: string;
  hint?: string;
};

export default function Textarea({
  label = "",
  hint = "",
  textareaProps = {},
}: Props) {
  return (
    <FormItem label={label} hint={hint}>
      <AntdInput.TextArea {...textareaProps} />
    </FormItem>
  );
}
