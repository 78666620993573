import { Button, Collapse } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { setData } from "reducers/data";
import StarterTypePanelItem from "./PanelItem";

export default function StarterType(): JSX.Element {
  const dispatch = useDispatch();
  const starterTypes = useSelector(
    (state: RootState) => state.data.starterTypes
  );
  const branches = useSelector((state: RootState) => state.data.branches);
  const branchOptions = branches.map(({ name, branchId }) => ({
    label: name,
    value: branchId,
  }));

  const [active, setActive] = useState<any>([]);

  const onChangeStarterType = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    dispatch(
      setData({
        starterTypes: [
          ...starterTypes.slice(0, index),
          { ...starterTypes[index], [name]: value },
          ...starterTypes.slice(index + 1),
        ],
      })
    );
  };

  const onChangeBranches = (branches: string[], index: number) => {
    dispatch(
      setData({
        starterTypes: [
          ...starterTypes.slice(0, index),
          { ...starterTypes[index], branches },
          ...starterTypes.slice(index + 1),
        ],
      })
    );
  };

  const onClickDelete = (index: number) => {
    dispatch(
      setData({
        starterTypes: [
          ...starterTypes.slice(0, index),
          ...starterTypes.slice(index + 1),
        ],
      })
    );
  };

  const onClickAdd = () => {
    dispatch(
      setData({
        starterTypes: [
          ...starterTypes,
          {
            period: "",
            label: "",
            visibleLabel: "",
            price: "",
            corporationLabel: "",
            corporationPrice: "",
            value: 10,
            branches: [],
          },
        ],
      })
    );
  };

  return (
    <div>
      <Collapse activeKey={active} onChange={(key) => setActive(key)}>
        {starterTypes.map((s, index) => (
          <Collapse.Panel header={s.label || " "} key={index}>
            <StarterTypePanelItem
              key={index}
              starterType={s}
              index={index}
              onChange={onChangeStarterType}
              onChangeBranches={onChangeBranches}
              onDelete={onClickDelete}
              branchOptions={branchOptions}
            />
          </Collapse.Panel>
        ))}
      </Collapse>
      <br />
      <br />
      <Button onClick={onClickAdd}>서비스 추가</Button>
    </div>
  );
}

// TODO
// 1. 테스트
// 2. 개인, 법인사업자 별 금액 다르게 표기 => 타입 수정 필요?
