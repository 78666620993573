import { Button, Collapse } from "antd";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { setData } from "reducers/data";
import Input from "@components/Input";
import Textarea from "@components/Textarea";
import update from "immutability-helper";

export default function Faq(): JSX.Element {
  const dispatch = useDispatch();
  const faqs = useSelector((state: RootState) => state.data.faqs);
  const [active, setActive] = useState<any>([]);

  const onChangeData = (e: any, index: number) => {
    const { name, value } = e.target;
    dispatch(
      setData({
        faqs: update(faqs, {
          [index]: { $set: { ...faqs[index], [name]: value } },
        }),
      })
    );
  };

  const onClickAdd = () => {
    const _faq: Faq = {
      faqId: faqs.length + 1,
      question: "새 FAQ",
      answer: "",
      value: 0,
      landingValue: 0,
    };
    dispatch(
      setData({
        faqs: [...faqs, _faq],
      })
    );
  };

  const onClickDelete = (index: number) => {
    dispatch(
      setData({
        faqs: [...faqs.slice(0, index), ...faqs.slice(index + 1)],
      })
    );
  };

  return (
    <div>
      <Collapse activeKey={active} onChange={(key) => setActive(key)}>
        {faqs.map((f, index) => (
          <Collapse.Panel header={f.question} key={index}>
            <Input
              label="아이디"
              hint="다른 FAQ와 중복 불가"
              inputProps={{
                name: "faqId",
                value: f.faqId,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <Input
              label="질문"
              hint=""
              inputProps={{
                name: "question",
                value: f.question,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <Textarea
              label="답변"
              textareaProps={{
                name: "answer",
                value: f.answer,
                onChange: (e) => onChangeData(e, index),
                style: { height: "200px" },
              }}
            />
            <Input
              label="정렬값"
              hint="높을수록 앞에 노출 (FAQ 페이지)"
              inputProps={{
                name: "value",
                value: f.value,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <Input
              label="랜딩페이지 정렬값"
              hint="높을수록 앞에 노출 (0이면 랜딩페이지에 노출 안함)"
              inputProps={{
                name: "landingValue",
                value: f.landingValue,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <br />
            <br />
            <Button type="dashed" onClick={() => onClickDelete(index)}>
              FAQ 제거
            </Button>
          </Collapse.Panel>
        ))}
      </Collapse>
      <br />
      <br />
      <Button onClick={onClickAdd}>FAQ 추가</Button>
    </div>
  );
}
