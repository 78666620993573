import React, { useEffect, useState } from "react";
import { notification, Tabs } from "antd";
import Promotion from "@components/Data/Promotion";
import Tools from "@components/Data/Tools";
import Garage from "@components/Data/Garage";
import { firestore } from "@utils/firebase";
import { useDispatch } from "react-redux";
import { setData } from "reducers/data";
import dayjs from "dayjs";
import Branch from "@components/Data/Branch";
import Membership from "@components/Data/Membership";
import Article from "@components/Data/Article";
import Benefit from "@components/Data/Benefit";
import Service from "@components/Data/Service";
import Faq from "@components/Data/Faq";
import StarterType from "@components/Data/StarterType";

let dataOnSnapshot = false;

export default function Data() {
  const dispatch = useDispatch();
  const tabPanes = [
    { label: "프로모션", key: "promotion", component: <Promotion /> },
    { label: "지점", key: "branch", component: <Branch /> },
    { label: "멤버십", key: "membership", component: <Membership /> },
    { label: "기사", key: "article", component: <Article /> },
    { label: "서비스", key: "service", component: <Service /> },
    { label: "베네핏", key: "benefit", component: <Benefit /> },
    { label: "FAQ", key: "faq", component: <Faq /> },
    {
      label: "스타터 계약 기간",
      key: "starter-type",
      component: <StarterType />,
    },
    { label: "기본정보", key: "garage", component: <Garage /> },
  ];
  const [activeTabKey, setActiveTabKey] = useState(tabPanes[0].key);
  const onChangeTab = (key: string) => setActiveTabKey(key);

  useEffect(() => {
    firestore()
      .collection("settings")
      .doc("data")
      .onSnapshot(async (snapshot) => {
        if (dataOnSnapshot) {
          notification.info({
            message: `데이터가 갱신되었습니다.`,
            description: dayjs().format("HH:mm:ss"),
            duration: 0,
          });
        }
        dispatch(setData(snapshot.data()));
        dataOnSnapshot = true;
      });
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1, paddingRight: "10px" }}>
        <Tabs
          defaultActiveKey="1"
          onChange={onChangeTab}
          activeKey={activeTabKey}
        >
          {tabPanes.map(({ label, key, component }) => (
            <Tabs.TabPane key={key} tab={label}>
              {component}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
      <div
        style={{
          width: "300px",
          borderLeft: "1px solid #e8e8e8",
          paddingLeft: "10px",
        }}
      >
        <Tools />
      </div>
    </div>
  );
}
