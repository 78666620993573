import { firestore } from "@utils/firebase";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import { PageHeader, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import Search from "@components/Search";

export default function Tour() {
  const [tours, setTours] = useState<any>([]);
  const branches = useSelector((state: RootState) => state.data.branches);
  const membershipTypes = branches
    .map(({ membershipTypes }) => membershipTypes)
    .reduce((a, b) => a.concat(b), []);

  const [results, setResults] = useState<any>([]);

  const onSearch = (query: string) => {
    const _results = tours.filter((item: object) =>
      JSON.stringify(item).includes(query)
    );
    setResults(_results);
  };

  const getData = async () => {
    const { docs } = await firestore()
      .collection("/tour")
      .orderBy("createAt", "desc")
      .get();
    const data = docs.map((doc) => doc.data());
    setTours(data);
    setResults(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns: ColumnsType<any> = [
    { title: "이름", dataIndex: "name", key: "name" },
    { title: "이메일", dataIndex: "email", key: "email" },
    { title: "전화번호", dataIndex: "phone", key: "phone" },
    {
      title: "지점",
      dataIndex: "branch",
      key: "branch",
      render: (value) => {
        const branch = branches.find(({ branchId }) => branchId === value);
        return branch?.name;
      },
      filters: branches.map(({ name, branchId }) => ({
        text: name,
        value: branchId,
      })),
      onFilter: (value, { branch }) => branch === value,
    },
    {
      title: "상담",
      dataIndex: "counseling",
      key: "counseling",
      render: (value) => {
        return Array.from(value).join(" / ");
      },
    },
    { title: "방문일", dataIndex: "date", key: "date" },
    { title: "서비스", dataIndex: "membership", key: "membership" },
    {
      title: "입주타입",
      dataIndex: "membershipType",
      key: "membershipType",
      render: (value) => {
        const membershipType = membershipTypes.find(
          ({ membershipTypeId }) => membershipTypeId === value
        );
        return membershipType?.name;
      },
    },
    { title: "방문경로", dataIndex: "path", key: "path" },
    { title: "메모", dataIndex: "comment", key: "comment" },
    { title: "생성시간", dataIndex: "createAt", key: "createAt" },
  ];

  return (
    <>
      <PageHeader title="투어" />
      <Table
        style={{ wordBreak: "keep-all" }}
        title={() => <Search onSearch={onSearch} />}
        dataSource={results}
        columns={columns}
        rowKey={"createAt"}
      />
    </>
  );
}
