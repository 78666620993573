import InputNumber from "@components/InputNumber";
import format from "@utils/format";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { setData } from "reducers/data";

type Props = {
  branch: Branch;
  branchIndex: number;
};
export default function Deposit({ branch, branchIndex }: Props) {
  const dispatch = useDispatch();
  const branches = useSelector((state: RootState) => state.data.branches);

  const onChange = (value: InputNumberChangeValue, name: string) => {
    dispatch(
      setData({
        branches: [
          ...branches.slice(0, branchIndex),
          { ...branches[branchIndex], [name]: value },
          ...branches.slice(branchIndex + 1),
        ],
      })
    );
  };
  return (
    <Row gutter={8}>
      <Col span={12}>
        <InputNumber
          label="개인사업자"
          inputNumberProps={{
            name: "individualDeposit",
            value: branch.individualDeposit,
            formatter: format.price,
            onChange: (value: InputNumberChangeValue) =>
              onChange(value, "individualDeposit"),
          }}
        />
      </Col>
      <Col span={12}>
        <InputNumber
          label="법인사업자"
          inputNumberProps={{
            name: "corporateDeposit",
            value: branch.corporateDeposit,
            formatter: format.price,
            onChange: (value: InputNumberChangeValue) =>
              onChange(value, "corporateDeposit"),
          }}
        />
      </Col>
    </Row>
  );
}
