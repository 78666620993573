import { Input } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

export default function Auth() {
  const { push } = useHistory();
  const [key, setKey] = useState("");
  const onPressEnter = () => {
    window.localStorage.setItem("key", key);
    push("/contact");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Input
          size="large"
          value={key}
          onChange={(e) => setKey(e.target.value)}
          onPressEnter={onPressEnter}
          style={{ width: "300px" }}
          type="password"
        />
      </div>
    </div>
  );
}
