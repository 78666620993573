import Input from "@components/Input";
import Tags from "@components/Tags";
import Switch from "@components/Switch";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { setData } from "reducers/data";
import { Space } from "antd";

type Props = { branch: Branch; branchIndex: number };
export default function BranchValue({ branch, branchIndex }: Props) {
  const dispatch = useDispatch();
  const branches = useSelector((state: RootState) => state.data.branches);

  const onChangeBranch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    dispatch(
      setData({
        branches: [
          ...branches.slice(0, branchIndex),
          { ...branches[branchIndex], [name]: value },
          ...branches.slice(branchIndex + 1),
        ],
      })
    );
  };

  const onChangeFlag = (flag: boolean, name: string) => {
    dispatch(
      setData({
        branches: [
          ...branches.slice(0, branchIndex),
          { ...branches[branchIndex], [name]: flag },
          ...branches.slice(branchIndex + 1),
        ],
      })
    );
  };

  const onChangeTags = (key: keyof Branch, tags: string[]) => {
    dispatch(
      setData({
        branches: [
          ...branches.slice(0, branchIndex),
          { ...branches[branchIndex], [key]: tags },
          ...branches.slice(branchIndex + 1),
        ],
      })
    );
  };

  return (
    <div>
      <h2>기본정보</h2>
      <Input
        label="지점 아이디"
        hint="중복 X"
        inputProps={{
          name: "branchId",
          value: branch.branchId,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Input
        label="이름"
        hint=""
        inputProps={{
          name: "name",
          value: branch.name,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Input
        label="영어이름"
        hint=""
        inputProps={{
          name: "englishName",
          value: branch.englishName,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Input
        label="주소"
        hint=""
        inputProps={{
          name: "address",
          value: branch.address,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Input
        label="상세주소"
        hint=""
        inputProps={{
          name: "addressDetail",
          value: branch.addressDetail,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Tags
        label="해시태그"
        selectProps={{
          value: branch.hashTags,
          onChange: (hashTags) => onChangeTags("hashTags", hashTags),
        }}
      />
      <Tags
        label="힌트"
        selectProps={{
          value: branch.hints,
          onChange: (hints) => onChangeTags("hints", hints),
        }}
      />
      <Space>
        <Switch
          label="주차가능"
          switchProps={{
            checked: branch.parkingFlag,
            onChange: (e) => onChangeFlag(e, "parkingFlag"),
          }}
        />
        <Switch
          label="24개월 문의"
          switchProps={{
            checked: branch.longTermPackageFlag,
            onChange: (e) => onChangeFlag(e, "longTermPackageFlag"),
          }}
        />
      </Space>
      <Input
        label="이메일"
        hint=""
        inputProps={{
          name: "email",
          value: branch.email,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Input
        label="지점 전화번호"
        hint=""
        inputProps={{
          name: "contact",
          value: branch.contact,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Input
        label="핸드폰번호"
        hint=""
        inputProps={{
          name: "phone",
          value: branch.phone,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Input
        label="카카오 지도 링크"
        hint=""
        inputProps={{
          name: "mapLink",
          value: branch.mapLink,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Input
        label="지도 이미지"
        hint=""
        inputProps={{
          name: "map",
          value: branch.map,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Input
        label="계좌정보"
        hint=""
        inputProps={{
          name: "account",
          value: branch.account,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Input
        label="대중교통"
        hint=""
        inputProps={{
          name: "publicTransport",
          value: branch.publicTransport,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Input
        label="자가용"
        hint=""
        inputProps={{
          name: "ownCar",
          value: branch.ownCar,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Input
        label="정렬 값"
        hint="지점 노출 시 정렬값 높은 순으로 노출"
        inputProps={{
          name: "value",
          value: branch.value,
          onChange: (e) => onChangeBranch(e),
        }}
      />
      <Input
        label="프로모션"
        hint="프로모션 링크 (없으면 빈칸)"
        inputProps={{
          name: "promotion",
          value: branch.promotion,
          onChange: (e) => onChangeBranch(e),
        }}
      />
    </div>
  );
}
