import Input from "@components/Input";
import MultiSelect from "@components/MultiSelect";
import { Button, Col, Row } from "antd";

type Props = {
  starterType: StarterType;
  index: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>, index: number) => void;
  onChangeBranches: (branches: string[], index: number) => void;
  onDelete: (index: number) => void;
  branchOptions: { label: string; value: string }[];
};

export default function StarterTypePanelItem({
  starterType,
  index,
  branchOptions,
  onChange,
  onChangeBranches,
  onDelete,
}: Props): JSX.Element {
  return (
    <>
      <Input
        label="기간"
        hint="3개월 일 경우 '3' 만 입력 / 중복 금지"
        inputProps={{
          name: "period",
          value: starterType.period,
          onChange: (e) => onChange(e, index),
        }}
      />
      <Input
        label="비상주 신청페이지에 보이는 내용"
        hint="ex) 24개월(5만 원/M) + 2개월 무료 + 10만원 유료크레딧 증정"
        inputProps={{
          name: "visibleLabel",
          value: starterType.visibleLabel,
          onChange: (e) => onChange(e, index),
        }}
      />
      <Row gutter={20}>
        <Col flex={1}>
          <Input
            label="개인사업자 비용상세"
            hint="ex) 3개월 이용료 270,000원(월 9만원)+예치금 50,000+부가세 27,000 / 이메일에 포함되는 내용"
            inputProps={{
              name: "label",
              value: starterType.label,
              onChange: (e) => onChange(e, index),
            }}
          />
          <Input
            label="개인사업자 금액"
            hint="ex) 50,000원 / 이메일에 포함되는 내용 / 총 금액"
            inputProps={{
              name: "price",
              value: starterType.price,
              onChange: (e) => onChange(e, index),
            }}
          />
        </Col>
        <Col flex={1}>
          <Input
            label="법인사업자 비용상세"
            hint="ex) 3개월 이용료 270,000원(월 9만원)+예치금 50,000+부가세 27,000 / 이메일에 포함되는 내용"
            inputProps={{
              name: "corporationLabel",
              value: starterType.corporationLabel,
              onChange: (e) => onChange(e, index),
            }}
          />
          <Input
            label="법인사업자 금액"
            hint="ex) 50,000원 / 이메일에 포함되는 내용 / 총 금액"
            inputProps={{
              name: "corporationPrice",
              value: starterType.corporationPrice,
              onChange: (e) => onChange(e, index),
            }}
          />
        </Col>
      </Row>
      <Input
        label="정렬값"
        hint="높을수록 위에"
        inputProps={{
          name: "value",
          value: starterType.value,
          onChange: (e) => onChange(e, index),
        }}
      />
      <MultiSelect
        label="적용지점"
        selectProps={{
          options: branchOptions,
          value: starterType.branches,
          onChange: (branches: string[]) => onChangeBranches(branches, index),
        }}
      />

      <br />
      <br />
      <Button type="dashed" onClick={() => onDelete(index)}>
        서비스 제거
      </Button>
    </>
  );
}
