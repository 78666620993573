import { firestore } from "@utils/firebase";
import { PageHeader, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers";
import { CheckOutlined } from "@ant-design/icons";
import Search from "@components/Search";

export default function Contact() {
  const branches = useSelector((state: RootState) => state.data.branches);
  const membershipTypes = branches
    .map(({ membershipTypes }) => membershipTypes)
    .reduce((a, b) => a.concat(b), []);
  const [contacts, setContacts] = useState<any>([]);
  const [results, setResults] = useState<any>([]);

  const onSearch = (query: string) => {
    const _results = contacts.filter((item: object) =>
      JSON.stringify(item).includes(query)
    );
    setResults(_results);
  };

  const getData = async () => {
    const { docs } = await firestore()
      .collection("/contact")
      .orderBy("createAt", "desc")
      .get();
    const data = docs.map((doc) => doc.data());
    setContacts(data);
    setResults(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns: ColumnsType<any> = [
    {
      title: "지점",
      key: "branch",
      dataIndex: "branch",
      align: "center",
      render: (value) => {
        const branch = branches.find(({ branchId }) => branchId === value);
        return branch?.name;
      },
      filters: branches.map(({ name, branchId }) => ({
        text: name,
        value: branchId,
      })),
      onFilter: (value, { branch }) => branch === value,
    },
    { title: "이름", key: "name", dataIndex: "name" },
    { title: "이메일", key: "email", dataIndex: "email" },
    { title: "전화번호", key: "phone", dataIndex: "phone" },
    { title: "방문경로", key: "path", dataIndex: "path", align: "center" },
    {
      title: "뉴스레터신청",
      key: "newsletter",
      dataIndex: "newsletter",
      align: "center",
      render: (value) => {
        return value ? <CheckOutlined /> : "";
      },
    },
    {
      title: "입주타입",
      dataIndex: "membershipType",
      key: "membershipType",
      render: (value) => {
        const membershipType = membershipTypes.find(
          ({ membershipTypeId }) => membershipTypeId === value
        );
        return membershipType?.name;
      },
    },
    { title: "내용", key: "comment", dataIndex: "comment" },
    {
      title: "생성시간",
      key: "createAt",
      dataIndex: "createAt",
      align: "center",
    },
  ];

  return (
    <>
      <PageHeader title="문의" />
      <Table
        style={{ wordBreak: "keep-all" }}
        title={() => <Search onSearch={onSearch} />}
        dataSource={results}
        columns={columns}
        rowKey={"createAt"}
      />
    </>
  );
}
