import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { setData } from "reducers/data";
import update from "immutability-helper";
import Input from "@components/Input";
import { Button, Col, Row } from "antd";

type Props = { branch: Branch; branchIndex: number };
export default function BranchValue({ branch, branchIndex }: Props) {
  const dispatch = useDispatch();
  const branches = useSelector((state: RootState) => state.data.branches);

  const onAddMembershipType = () => {
    dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: {
            $set: {
              ...branches[branchIndex],
              membershipTypes: [
                ...branches[branchIndex].membershipTypes,
                { name: "", membershipTypeId: "" },
              ],
            },
          },
        }),
      })
    );
  };

  const onChangeData = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: {
            $set: {
              ...branches[branchIndex],
              membershipTypes: [
                ...branches[branchIndex].membershipTypes.slice(0, index),
                {
                  ...branches[branchIndex].membershipTypes[index],
                  [name]: value,
                },
                ...branches[branchIndex].membershipTypes.slice(index + 1),
              ],
            },
          },
        }),
      })
    );
  };

  const onDeleteMembershipType = (index: number) => {
    dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: {
            $set: {
              ...branches[branchIndex],
              membershipTypes: [
                ...branches[branchIndex].membershipTypes.slice(0, index),
                ...branches[branchIndex].membershipTypes.slice(index + 1),
              ],
            },
          },
        }),
      })
    );
  };

  return (
    <div>
      <h2>
        입주타입
        <Button size="small" onClick={onAddMembershipType}>
          입주타입추가
        </Button>
      </h2>
      {branch.membershipTypes.map(({ membershipTypeId, name }, index) => (
        <Row gutter={16} key={index}>
          <Col span={4}>
            <Input
              hint="다른거랑 겹치면 X"
              inputProps={{
                name: "membershipTypeId",
                value: membershipTypeId,
                onChange: (e) => onChangeData(e, index),
              }}
            />
          </Col>
          <Col span={12}>
            <Input
              inputProps={{
                name: "name",
                value: name,
                onChange: (e) => onChangeData(e, index),
                suffix: (
                  <Button
                    size="small"
                    onClick={() => onDeleteMembershipType(index)}
                  >
                    제거
                  </Button>
                ),
              }}
            />
          </Col>
        </Row>
      ))}
    </div>
  );
}
