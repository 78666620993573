import React from "react";
import { Input as AntdInput, InputProps } from "antd";
import FormItem from "@components/FormItem";

type Props = {
  inputProps?: InputProps;
  label?: string;
  hint?: string;
  noBottomSpace?: boolean;
};

export default function Input({
  label = "",
  hint = "",
  inputProps = {},
  noBottomSpace,
}: Props) {
  return (
    <FormItem label={label} hint={hint} noBottomSpace={noBottomSpace}>
      <AntdInput {...inputProps} />
    </FormItem>
  );
}
