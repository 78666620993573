import { Button, Collapse } from "antd";
import { ReactNode, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import BranchHoliday from "./Holiday";
import BranchImage from "./Image";
import BranchValue from "./Value";
import BranchMembershipType from "./MembershipType";
import BranchActive from "./BranchActive";
import BranchDeposit from "./Deposit";
import BranchContractPackages from "./ContractPackages";
import { setData } from "reducers/data";
import { CaretRightOutlined } from "@ant-design/icons";

export default function Branch() {
  const dispatch = useDispatch();
  const branches = useSelector((state: RootState) => state.data.branches);
  const [active, setActive] = useState<any>([]);

  const onClickAdd = () => {
    const _branch: Branch = {
      branchId: "",
      name: "새 지점",
      englishName: "",
      address: "",
      addressDetail: "",
      parkingFlag: false,
      longTermPackageFlag: true,
      hashTags: [],
      hints: [],
      imageUris: [],
      email: "",
      contact: "",
      phone: "",
      mapLink: "",
      map: "",
      membershipTypes: [],
      holidays: [],
      account: "",
      publicTransport: "",
      ownCar: "",
      value: 0,
      promotion: "",
      individualDeposit: 0,
      corporateDeposit: 0,
      contractPackages: [],
      branchActive: true
    };
    dispatch(
      setData({
        branches: [...branches, _branch],
      })
    );
    setActive(branches.length);
  };

  const onClickDelete = (index: number) => {
    dispatch(
      setData({
        branches: [...branches.slice(0, index), ...branches.slice(index + 1)],
      })
    );
  };

  return (
    <div>
      <Collapse activeKey={active} onChange={(key) => setActive(key)}>
        {branches.map((branch, branchIndex) => (
          <Collapse.Panel header={branch.name} key={branchIndex}>
            <EditableItemGroup branch={branch} branchIndex={branchIndex} />
            <br />
            <br />
            <Button type="dashed" onClick={() => onClickDelete(branchIndex)}>
              지점 제거
            </Button>
          </Collapse.Panel>
        ))}
      </Collapse>
      <br />
      <br />
      <Button onClick={onClickAdd}>지점 추가</Button>
    </div>
  );
}

export function EditableItemGroup({
  branch,
  branchIndex,
}: {
  branch: Branch;
  branchIndex: number;
}) {
  const panels: { header: ReactNode; key: string; component: ReactNode }[] = [
    {
      header: <h3>기본정보</h3>,
      key: "detail",
      component: <BranchValue branch={branch} branchIndex={branchIndex} />,
    },
    {
      header: <h3>사진</h3>,
      key: "photo",
      component: <BranchImage branch={branch} branchIndex={branchIndex} />,
    },
    {
      header: <h3>휴일</h3>,
      key: "holiday",
      component: <BranchHoliday branch={branch} branchIndex={branchIndex} />,
    },
    {
      header: <h3>입주타입</h3>,
      key: "membershipType",
      component: (
        <BranchMembershipType branch={branch} branchIndex={branchIndex} />
      ),
    },
    {
      header: <h3>지점 활성</h3>,
      key: "branchActive",
      component: <BranchActive branch={branch} branchIndex={branchIndex} />,
    },
    {
      header: <h3>보증금</h3>,
      key: "deposit",
      component: <BranchDeposit branch={branch} branchIndex={branchIndex} />,
    },
    {
      header: <h3>계약 패키지</h3>,
      key: "contractPackages",
      component: (
        <BranchContractPackages branch={branch} branchIndex={branchIndex} />
      ),
    },
  ];
  return (
    <Collapse
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      ghost
    >
      {panels.map(({ header, key, component }) => (
        <Collapse.Panel header={header} key={key}>
          {component}
        </Collapse.Panel>
      ))}
    </Collapse>
  );
}
