import { Button, Row, Col, Select } from "antd";
import dayjs from "dayjs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { setData } from "reducers/data";
import update from "immutability-helper";

const times: string[] = [
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
];

type Props = { branch: Branch; branchIndex: number };
export default function BranchHoliday({ branch, branchIndex }: Props) {
  const dispatch = useDispatch();
  const branches = useSelector((state: RootState) => state.data.branches);

  const onAddHoliday = () => {
    dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: {
            $set: {
              ...branches[branchIndex],
              holidays: [
                ...branches[branchIndex].holidays,
                {
                  date: dayjs().format("YYYY-MM-DD"),
                  alldayFlag: true,
                  time: [],
                },
              ],
            },
          },
        }),
      })
    );
  };

  const onDeleteHoliday = (holidayIndex: number) => {
    const branch = branches[branchIndex];
    const holidays = branch.holidays;
    holidays.splice(holidayIndex, 1);

    dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: { $set: { ...branch, holidays } },
        }),
      })
    );
  };

  const onChangeTime = (time: string[], holidayIndex: number) => {
    const branch = branches[branchIndex];
    const holidays = branch.holidays;

    holidays[holidayIndex].time = time;
    holidays[holidayIndex].alldayFlag = time.length === 0;

    dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: { $set: { ...branch, holidays } },
        }),
      })
    );
  };

  const onChangeDate = (
    e: React.ChangeEvent<HTMLInputElement>,
    holidayIndex: number
  ) => {
    const branch = branches[branchIndex];
    const holidays = branch.holidays;

    holidays[holidayIndex].date = e.target.value;
    dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: { $set: { ...branch, holidays } },
        }),
      })
    );
  };
  return (
    <div>
      <h2>
        휴일
        <Button size="small" onClick={onAddHoliday}>
          휴일추가
        </Button>
      </h2>
      {branch.holidays.map(({ date, alldayFlag, time }, holidayIndex) => (
        <Row gutter={16} key={holidayIndex}>
          <Col>
            <input
              type="date"
              value={date}
              onChange={(e) => onChangeDate(e, holidayIndex)}
            />
          </Col>
          <Col sm={12}>
            <Select
              mode="multiple"
              placeholder="시간을 선택하지 않으면 하루 전체 휴일"
              value={time}
              onChange={(value) => onChangeTime(value, holidayIndex)}
              style={{ width: "100%" }}
            >
              {times.map((t) => (
                <Select.Option key={t} value={t}>
                  {t}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Button
              size="small"
              color="error"
              onClick={() => onDeleteHoliday(holidayIndex)}
            >
              휴일제거
            </Button>
          </Col>
        </Row>
      ))}
    </div>
  );
}
