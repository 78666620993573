import React from "react";
import { Menu } from "antd";
import { useHistory, useLocation } from "react-router-dom";

export default function Nav() {
  const { push } = useHistory();
  const { pathname } = useLocation();

  const menus = [
    { link: "/newsletter", label: "뉴스레터" },
    { link: "/tour", label: "투어" },
    { link: "/contact", label: "문의" },
    { link: "/starter", label: "스타터" },
    { link: "/partnership", label: "파트너십" },
    { link: "/space", label: "스페이스" },
    { link: "/data", label: "데이터 수정" },
  ];

  return (
    <nav>
      <Menu
        mode="horizontal"
        onClick={(e) => push(String(e.key))}
        activeKey={pathname}
      >
        {menus.map(({ link, label }) => {
          return <Menu.Item key={link}>{label}</Menu.Item>;
        })}
      </Menu>
    </nav>
  );
}
