import Search from "@components/Search";
import RefundAccountModal from "@components/Starter/RefundAccountModal";
import { firestore } from "@utils/firebase";
import { Button, PageHeader, Select, Table, Tag, message } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers";

export default function Starter() {
  const branches = useSelector((state: RootState) => state.data.branches);
  const [starters, setStarters] = useState<any>([]);
  const [results, setResults] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [refundReceiveAccount, setRefundReceiveAccount] =
    useState<RefundReceiveAccount>(null);

  const onSearch = (query: string) => {
    const _results = starters.filter((item: object) =>
      JSON.stringify(item).includes(query)
    );
    setResults(_results);
  };


  const onChangePaymentStatus = async (data: any, id: string) => {
    console.log(data, id);
    setLoading(true);
    await firestore()
      .collection("starter")
      .doc(id)
      .update({ paymentConfirmResponse: { data: { ...data } } });
    setLoading(false);
    message.success("저장되었습니다.");
  };

  const onClickModal = (refundReceiveAccount: RefundReceiveAccount) => {
    setRefundReceiveAccount(refundReceiveAccount);
    setOpenModal(true);
  };

  const getData = async () => {
    const { docs } = await firestore()
      .collection("/starter")
      .orderBy("createAt", "desc")
      .get();
    const data = docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    setStarters(data);
    setResults(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns: ColumnsType<StarterTable> = [
    { title: "이름", key: "name", dataIndex: "name", fixed: "left" },
    { title: "이메일", key: "email", dataIndex: "email", fixed: "left" },
    { title: "전화번호", key: "phone", dataIndex: "phone", fixed: "left" },
    { title: "주소", key: "address", dataIndex: "address" },
    { title: "상세주소", key: "addressDetail", dataIndex: "addressDetail" },
    {
      title: "지점",
      key: "branch",
      dataIndex: "branch",
      render: (value) => {
        const branch = branches.find(
          ({ branchId }: { branchId: string }) => branchId === value
        );
        return branch?.name;
      },
      filters: branches.map(
        ({ name, branchId }: { name: string; branchId: string }) => ({
          text: name,
          value: branchId,
        })
      ),
      onFilter: (value, { branch }) => branch === value,
    },
    { title: "구분", key: "businessCategory", dataIndex: "businessCategory" },
    { title: "유형", key: "businessType", dataIndex: "businessType" },
    { title: "회사명", key: "businessName", dataIndex: "businessName" },
    { title: "계약 시작일", key: "contractDate", dataIndex: "contractDate" },
    { title: "계약 상품", key: "packageName", dataIndex: "packageName" },
    {
      title: "계약 기간",
      key: "contractPeriod",
      dataIndex: "contractPeriod",
      width: 90,
    },
    { title: "생성시간", key: "createAt", dataIndex: "createAt" },

    {
      title: "결제 수단",
      key: "method",
      dataIndex: "payment",
      render: (_, { paymentConfirmResponse }) => {
        const { virtualAccount } = paymentConfirmResponse?.data || "";
        const method = paymentConfirmResponse?.data?.method || "";
        const refund = virtualAccount?.refundReceiveAccount || null;
        if (virtualAccount)
          return <Button onClick={() => onClickModal(refund)}>{method}</Button>;
        return <span>{method}</span>;
      },
    },
    {
      title: "주문번호",
      key: "orderId",
      dataIndex: "payment",
      render: (_, { paymentConfirmResponse }) => (
        <span>{paymentConfirmResponse?.data?.orderId || ""}</span>
      ),
    },
    {
      title: "결제상태",
      key: "status",
      dataIndex: "payment",
      render: (_, { paymentConfirmResponse, id }) => {
        const status = paymentConfirmResponse?.data?.status;
        const statusOptions: {
          label: ReactNode;
          value: string;
          color: string;
        }[] = [
          {
            label: <Tag color="success">완료</Tag>,
            value: "DONE",
            color: "success",
          },
          {
            label: <Tag color="error">취소</Tag>,
            value: "CANCELED",
            color: "error",
          },
          {
            label: <Tag color="magenta">부분취소</Tag>,
            value: "PARTIAL_CANCELED",
            color: "error",
          },
          {
            label: <Tag color="warning">입금대기</Tag>,
            value: "WAITING_FOR_DEPOSIT",
            color: "warning",
          },
        ];
        return status ? (
          <Select
            defaultValue={status}
            options={statusOptions}
            bordered={false}
            onChange={(value) =>
              onChangePaymentStatus(
                { ...paymentConfirmResponse.data, status: value },
                id
              )
            }
          />
        ) : (
          ""
        );
      },
    },
  ];

  return (
    <>
      <PageHeader title="스타터" />
      <Table
        style={{ wordBreak: "keep-all" }}
        title={() => <Search onSearch={onSearch} />}
        dataSource={results}
        loading={loading}
        columns={columns}
        rowKey={"createAt"}
        scroll={{ x: 2000 }}
      />
      <RefundAccountModal
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        refundReceiveAccount={refundReceiveAccount}
      />
    </>
  );
}
