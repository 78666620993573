import { InputNumber as AntdInputNumber, InputNumberProps } from "antd";
import FormItem from "@components/FormItem";

type Props = {
  inputNumberProps?: InputNumberProps;
  label?: string;
  hint?: string;
  noBottomSpace?: boolean;
  format?: (value: string | number | undefined) => string;
};

export default function InputNumber({
  label = "",
  hint = "",
  inputNumberProps = {},
  noBottomSpace,
}: Props) {
  return (
    <FormItem label={label} hint={hint} noBottomSpace={noBottomSpace}>
      <AntdInputNumber
        {...inputNumberProps}
        style={{ textAlign: "right", width: "100%" }}
      />
    </FormItem>
  );
}
