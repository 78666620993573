import FormItem from "@components/FormItem";
import { useEffect, useRef } from "react";
import Quill from "react-quill";
import "react-quill/dist/quill.snow.css";
import style from "./style.module.scss";

type Props = {
  id: string;
  texteditorProps?: any;
  label?: string;
  hint?: string;
};

export default function TextEditor({
  id,
  label = "",
  hint = "",
  texteditorProps = {},
}: Props) {
  const quill = useRef<Quill>(null);

  const addImageClass = () => {
    const quillElement = document.querySelector(`#text-editor-${id}`);
    const editorElement = quillElement?.querySelector(
      ".ql-container .ql-editor"
    );

    const imgElements = Array.from(
      editorElement?.querySelectorAll("img") || []
    );

    for (const img of imgElements) {
      const mobileOnly = img.src.includes("_mobile");
      const pcOnly = img.src.includes("_pc");

      if (mobileOnly) img.classList.add("mobileOnly");
      if (pcOnly) img.classList.add("pcOnly");
    }

    texteditorProps.onChange(editorElement?.innerHTML);
  };

  useEffect(() => {
    if (!quill?.current) return;

    const editor = quill.current.getEditor();

    const toolbar = editor.getModule("toolbar");
    toolbar.handlers.image = async () => {
      const url = window.prompt("이미지 url");
      if (!url) return;
      const range = editor.getSelection();
      if (!range) return;

      editor.insertEmbed(range.index, "image", url);
      addImageClass();
    };
  }, [quill]);

  return (
    <FormItem label={label} hint={hint}>
      <div className={style.quillWrapper}>
        <Quill
          id={`text-editor-${id}`}
          {...texteditorProps}
          ref={quill}
          modules={{
            toolbar: [
              ["bold", "italic", "underline", "strike"], // toggled buttons
              ["blockquote"],

              [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }], // superscript/subscript
              [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
              [{ direction: "rtl" }], // text direction

              [{ size: ["small", false, "large", "huge"] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],

              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              [{ align: [] }],

              ["link", "image"],

              ["clean"], // remove formatting button
            ],
          }}
        />
      </div>
    </FormItem>
  );
}
