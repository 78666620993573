import React from "react";
import { Switch as AntdSwitch, SwitchProps } from "antd";
import FormItem from "./FormItem";

type Props = {
  switchProps?: SwitchProps;
  label?: string;
  hint?: string;
};

export default function Switch({
  label = "",
  hint = "",
  switchProps = {},
}: Props) {
  return (
    <FormItem label={label} hint={hint}>
      <AntdSwitch {...switchProps} />
    </FormItem>
  );
}
