import { Button, Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { setData } from "reducers/data";
import update from "immutability-helper";
import Input from "@components/Input";

import React, { useState } from "react";
import InputNumber from "@components/InputNumber";
import { format } from "@utils/format";
import DiscountCalculator from "@components/DiscountCalculator";
import { CalculatorTwoTone } from "@ant-design/icons";

type Props = {
  branch: Branch;
  branchIndex: number;
};
export default function ContractPackages({ branch, branchIndex }: Props) {
  const dispatch = useDispatch();
  const branches = useSelector((state: RootState) => state.data.branches);

  const [showCalculator, setShowCalculator] = useState(false);

  const packageRow: Record<
    keyof ContractPackageType,
    { title: string; colSpan: number }
  > = {
    name: { title: "패키지 이름", colSpan: 3 },
    serviceMonths: { title: "제공 기간(월)", colSpan: 3 },
    originalPrice: { title: "정가", colSpan: 4 },
    discountedPrice: { title: "할인액", colSpan: 4 },
    discountRate: { title: "할인율", colSpan: 2 },
    additionals: { title: "추가혜택", colSpan: 7 },
  };

  const initialValues = {
    name: "",
    serviceMonths: 0,
    originalPrice: 0,
    discountedPrice: 0,
    discountRate: 0,
    additionals: "",
  };

  const onAddContractPackage = () => {
    dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: {
            $set: {
              ...branches[branchIndex],
              contractPackages: [
                ...branches[branchIndex].contractPackages,
                initialValues,
              ],
            },
          },
        }),
      })
    );
  };

  const updateContractPackages = (
    value: InputNumberChangeValue,
    name: string,
    index: number
  ) =>
    dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: {
            $set: {
              ...branches[branchIndex],
              contractPackages: [
                ...branches[branchIndex].contractPackages.slice(0, index),
                {
                  ...branches[branchIndex].contractPackages[index],
                  [name]: value,
                },
                ...branches[branchIndex].contractPackages.slice(index + 1),
              ],
            },
          },
        }),
      })
    );
  const onChangeNumber = (
    value: InputNumberChangeValue,
    name: string,
    index: number
  ) => {
    updateContractPackages(Number(value), name, index);
  };

  const onChangeData = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    updateContractPackages(value, name, index);
  };

  const onDeleteContractPackage = (index: number) => {
    dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: {
            $set: {
              ...branches[branchIndex],
              contractPackages: [
                ...branches[branchIndex].contractPackages.slice(0, index),
                ...branches[branchIndex].contractPackages.slice(index + 1),
              ],
            },
          },
        }),
      })
    );
  };

  return (
    <div>
      <Row justify="end">
        <Button size="small" onClick={onAddContractPackage}>
          계약패키지 추가
        </Button>
      </Row>
      <Row gutter={[16, 4]} align="middle">
        <Col span={packageRow.name.colSpan}>{packageRow.name.title}</Col>
        <Col
          span={packageRow.serviceMonths.colSpan}
          style={styles.numerColumnTitle}
        >
          {packageRow.serviceMonths.title}
        </Col>
        <Col
          span={packageRow.originalPrice.colSpan}
          style={styles.numerColumnTitle}
        >
          {packageRow.originalPrice.title}
        </Col>
        <Col
          span={packageRow.discountedPrice.colSpan}
          style={styles.numerColumnTitle}
        >
          {packageRow.discountedPrice.title}
        </Col>
        <Col
          span={packageRow.discountRate.colSpan}
          style={styles.numerColumnTitle}
        >
          {packageRow.discountRate.title}
        </Col>
        <Col span={packageRow.additionals.colSpan}>
          {packageRow.additionals.title}
        </Col>
        <Col span={1}></Col>
      </Row>
      {branch.contractPackages.map(
        (
          {
            additionals,
            discountRate,
            discountedPrice,
            originalPrice,
            serviceMonths,
            name,
          },
          index
        ) => {
          return (
            <div>
              <Row gutter={[16, 16]} key={index} align="middle">
                <Col span={packageRow.name.colSpan}>
                  <Input
                    inputProps={{
                      name: "name",
                      value: name,
                      onChange: (e) => onChangeData(e, index),
                    }}
                    noBottomSpace
                  />
                </Col>
                <Col span={3}>
                  <InputNumber
                    inputNumberProps={{
                      name: "serviceMonths",
                      value: serviceMonths,
                      onChange: (value: InputNumberChangeValue) =>
                        onChangeNumber(value, "serviceMonths", index),
                    }}
                    noBottomSpace
                  />
                </Col>
                <Col span={4}>
                  <InputNumber
                    inputNumberProps={{
                      name: "originalPrice",
                      value: originalPrice,
                      formatter: format.price,
                      onChange: (value: InputNumberChangeValue) =>
                        onChangeNumber(value, "originalPrice", index),
                    }}
                    noBottomSpace
                  />
                </Col>
                <Col span={4}>
                  <InputNumber
                    inputNumberProps={{
                      name: "discountedPrice",
                      value: discountedPrice,
                      formatter: format.price,
                      onChange: (value: InputNumberChangeValue) => {
                        onChangeNumber(value, "discountedPrice", index);
                      },
                    }}
                    noBottomSpace
                  />
                </Col>
                <Col span={2}>
                  <InputNumber
                    inputNumberProps={{
                      name: "discountRate",
                      defaultValue: discountRate,
                      value: discountRate,
                      formatter: format.percent,
                      onChange: (value: InputNumberChangeValue) =>
                        onChangeNumber(value, "discountRate", index),
                    }}
                    noBottomSpace
                  />
                </Col>
                <Col span={7}>
                  <Input
                    inputProps={{
                      name: "additionals",
                      value: additionals,

                      onChange: (e) => onChangeData(e, index),
                    }}
                    noBottomSpace
                  />
                </Col>
                <Col span={1}>
                  <Button
                    type="link"
                    shape="circle"
                    onClick={() => onDeleteContractPackage(index)}
                  >
                    삭제
                  </Button>
                </Col>
              </Row>
            </div>
          );
        }
      )}

      <div style={styles.calculatorWrapper}>
        <Button
          size="small"
          type="link"
          onClick={() => setShowCalculator(!showCalculator)}
        >
          <CalculatorTwoTone /> 계산기
        </Button>
      </div>
      {showCalculator && <DiscountCalculator />}
    </div>
  );
}

const styles: Record<string, React.CSSProperties> = {
  numerColumnTitle: { textAlign: "right" },
  calculatorWrapper: { display: "flex", gap: "10px" },
};
