import { firestore, storage } from "@utils/firebase";
import { Button, notification, Spin, Typography } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "reducers";

import style from "./style.module.scss";

export default function Tools() {
  const data = useSelector((state: RootState) => state.data);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const onClickSave = async () => {
    setLoading(true);
    await firestore().collection("settings").doc("data").set(data);
    setLoading(false);
    notification.success({ message: "저장완료" });
  };

  const onDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    const div = e.target as HTMLDivElement;
    div.classList.remove(style.over);
    e.preventDefault();
    if (uploading) return;

    const files = Array.from(e.dataTransfer.items)
      .filter(({ kind }) => kind === "file")
      .map((item) => item.getAsFile());

    const file = files[0];
    if (!file) return;

    setUploading(true);

    const path = `/images/${Date.now()}-${file.name}`;
    await storage().ref(path).put(file, { contentType: file.type });
    const uri = await storage().ref(path).getDownloadURL();
    notification.success({
      description: file.type.startsWith("image") ? (
        <img src={uri} alt="upload-result" className={style.uploadResult} />
      ) : (
        ""
      ),
      message: (
        <Typography.Text copyable={{ text: uri }}>{file.name}</Typography.Text>
      ),
      duration: 0,
    });

    setUploading(false);
  };
  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    const div = e.target as HTMLDivElement;
    div.classList.add(style.over);
    e.preventDefault();
  };
  const onDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    const div = e.target as HTMLDivElement;
    div.classList.remove(style.over);
    e.preventDefault();
  };

  return (
    <div style={{ position: "sticky", top: "10px" }}>
      <div
        className={style.dragable}
        onDrop={onDrop}
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
      >
        {uploading ? <Spin /> : "파일 업로드"}
      </div>
      <div style={{ paddingTop: "10px" }}>
        프로모션 이미지 업로드 시
        <br />
        <ul>
          <li>- 모바일 전용 이미지일 경우 파일명에 _mobile 포함</li>
          <li>- PC 전용 이미지일 경우 파일명에 _pc 포함</li>
          <li>- _mobile, _pc 둘 다 없을 경우 둘다 표기</li>
        </ul>
        <b>프로모션 추가/수정 시</b>
        <br />
        <b>다른 프로모션과 중복된 아이디 값이 아닌지 확인해 주세요</b>
      </div>
      <Button
        loading={loading}
        size="large"
        type="primary"
        block
        style={{ marginTop: "30px" }}
        onClick={onClickSave}
      >
        저장
      </Button>
    </div>
  );
}
