import React from "react";
import { Select as AntdSelect, SelectProps } from "antd";
import FormItem from "@components/FormItem";

type Props<T> = {
  selectProps?: SelectProps<T>;
  label?: string;
  hint?: string;
};

export default function Tags({
  label = "",
  hint = "",
  selectProps = {},
}: Props<string[]>) {
  return (
    <FormItem label={label} hint={hint}>
      <AntdSelect mode="tags" style={{ width: "100%" }} {...selectProps} />
    </FormItem>
  );
}
