import { Button, Descriptions, Modal } from "antd";
import { BankLabels } from "variables";

type RefundAccountModalProps = {
  visible: boolean;
  onCancel: () => void;
  refundReceiveAccount?: {
    bankCode: string;
    accountNumber: string;
    holderName: string;
  } | null;
};
export default function RefundAccountModal({
  visible,
  onCancel,
  refundReceiveAccount,
}: RefundAccountModalProps) {
  const bankCode: string = refundReceiveAccount?.bankCode || "none";
  const accountNumber = refundReceiveAccount?.accountNumber || "-";
  const holderName = refundReceiveAccount?.holderName || "-";
  return (
    <Modal
      visible={visible}
      title="가상계좌 환불 정보"
      footer={<Button onClick={onCancel}>닫기</Button>}
      onCancel={onCancel}
    >
      <Descriptions bordered layout="vertical">
        <Descriptions.Item label="은행 코드">
          {BankLabels[bankCode]}
        </Descriptions.Item>
        <Descriptions.Item label="계좌번호">{accountNumber}</Descriptions.Item>
        <Descriptions.Item label="예금주 정보">{holderName}</Descriptions.Item>
      </Descriptions>
    </Modal>
  );
}
