import InputNumber from "@components/InputNumber";
import format from "@utils/format";
import { Button, Col, Row } from "antd";
import { blue } from "@ant-design/colors";

import { CSSProperties, useState } from "react";

type CalcResult = number | string;

type DiscountedPriceCalcValues = {
  originalValue: InputNumberChangeValue;
  percentageValue: InputNumberChangeValue;
  result: CalcResult;
};

type DiscountRateCalcValues = {
  originalValue: InputNumberChangeValue;
  discountedValue: InputNumberChangeValue;
  result: CalcResult;
};

export default function DiscountCalculator() {
  const [discountedPriceCalcValues, setDiscountedPriceCalcValues] =
    useState<DiscountedPriceCalcValues>({
      originalValue: 0,
      percentageValue: 0,
      result: 0,
    });
  const [discountRateCalcValues, setDiscountRateCalcValues] =
    useState<DiscountRateCalcValues>({
      originalValue: 0,
      discountedValue: 0,
      result: 0,
    });

  const onClickDiscountRate = () => {
    const { originalValue, discountedValue } = discountRateCalcValues;
    let result;

    if (originalValue && discountedValue) {
      result = 100 - (Number(discountedValue) / Number(originalValue)) * 100;
    } else {
      result = "";
    }
    setDiscountRateCalcValues({
      ...discountRateCalcValues,
      result,
    });
  };

  const onClickDiscountedPrice = () => {
    const { originalValue, percentageValue } = discountedPriceCalcValues;
    let result;

    if (originalValue && percentageValue) {
      result =
        Number(originalValue) -
        (Number(percentageValue) * Number(originalValue)) / 100;
    } else {
      result = "";
    }
    setDiscountedPriceCalcValues({
      ...discountedPriceCalcValues,
      result,
    });
  };

  return (
    <Row style={{ padding: "10px" }}>
      <Col>
        <InputNumber
          label="정가"
          inputNumberProps={{
            formatter: format.price,
            onChange: (value) => {
              setDiscountedPriceCalcValues({
                ...discountedPriceCalcValues,
                originalValue: value,
              });
            },
          }}
        />
        <InputNumber
          label="할인율(%)"
          inputNumberProps={{
            formatter: format.percent,
            onChange: (value) => {
              setDiscountedPriceCalcValues({
                ...discountedPriceCalcValues,
                percentageValue: value,
              });
            },
          }}
        />
        <Button block onClick={onClickDiscountedPrice}>
          할인액 구하기
        </Button>
        <div style={styles.result}>
          {discountedPriceCalcValues.result.toLocaleString()}
        </div>
      </Col>
      <div style={styles.divider} />
      <Col>
        <InputNumber
          label="정가"
          format={format.price}
          inputNumberProps={{
            formatter: format.price,
            onChange: (value) => {
              setDiscountRateCalcValues({
                ...discountRateCalcValues,
                originalValue: value,
              });
            },
          }}
        />
        <InputNumber
          label="할인액"
          format={format.price}
          inputNumberProps={{
            formatter: format.price,
            onChange: (value) => {
              setDiscountRateCalcValues({
                ...discountRateCalcValues,
                discountedValue: value,
              });
            },
          }}
        />
        <Button block onClick={onClickDiscountRate}>
          할인율 구하기
        </Button>
        <div style={styles.result}>
          {`${discountRateCalcValues.result || ""}%`}
        </div>
      </Col>
    </Row>
  );
}

const styles: Record<string, CSSProperties> = {
  result: {
    textAlign: "right",
    fontSize: "18px",
    fontWeight: 200,
    color: blue.primary,
  },
  divider: { margin: "24px", width: "1px", background: "#f2f2f2" },
};
