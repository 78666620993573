import Input from "@components/Input";
import { Col, Divider, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { setData } from "reducers/data";

export default function Garage() {
  const dispatch = useDispatch();
  const garage = useSelector((state: RootState) => state.data.garage);

  const onChangeGarage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(setData({ garage: { ...garage, [name]: value } }));
  };

  const onChangeGarageEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(
      setData({
        garage: { ...garage, email: { ...garage.email, [name]: value } },
      })
    );
  };

  return (
    <div>
      <Row gutter={16}>
        <Col sm={4}>
          <Input
            label="회사"
            hint=""
            inputProps={{
              name: "company",
              value: garage.company,
              onChange: onChangeGarage,
            }}
          />
        </Col>
        <Col sm={4}>
          <Input
            label="대표"
            hint=""
            inputProps={{
              name: "representative",
              value: garage.representative,
              onChange: onChangeGarage,
            }}
          />
        </Col>
        <Col sm={4}>
          <Input
            label="사업자번호"
            hint=""
            inputProps={{
              name: "businessLicence",
              value: garage.businessLicence,
              onChange: onChangeGarage,
            }}
          />
        </Col>
        <Col sm={4}>
          <Input
            label="운영시간"
            hint=""
            inputProps={{
              name: "operationHour",
              value: garage.operationHour,
              onChange: onChangeGarage,
            }}
          />
        </Col>
        <Col sm={4}>
          <Input
            label="휴식시간"
            hint=""
            inputProps={{
              name: "breakTime",
              value: garage.breakTime,
              onChange: onChangeGarage,
            }}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col sm={6}>
          <Input
            label="이메일 | PR/제휴"
            hint=""
            inputProps={{
              name: "pr",
              value: garage.email.pr,
              onChange: onChangeGarageEmail,
            }}
          />
        </Col>
        <Col sm={6}>
          <Input
            label="이메일 | 공유오피스 설립"
            hint=""
            inputProps={{
              name: "business",
              value: garage.email.business,
              onChange: onChangeGarageEmail,
            }}
          />
        </Col>
        <Col sm={6}>
          <Input
            label="이메일 | support"
            hint=""
            inputProps={{
              name: "support",
              value: garage.email.support,
              onChange: onChangeGarageEmail,
            }}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col sm={6}>
          <Input
            label="SNS | FACEBOOK"
            hint=""
            inputProps={{
              name: "facebook",
              value: garage.facebook,
              onChange: onChangeGarage,
            }}
          />
        </Col>
        <Col sm={6}>
          <Input
            label="SNS | INSTAGRAM"
            hint=""
            inputProps={{
              name: "instagram",
              value: garage.instagram,
              onChange: onChangeGarage,
            }}
          />
        </Col>
        <Col sm={6}>
          <Input
            label="SNS | KAKAO"
            hint=""
            inputProps={{
              name: "kakao",
              value: garage.kakao,
              onChange: onChangeGarage,
            }}
          />
        </Col>
        <Col sm={6} />
        <Col sm={6}>
          <Input
            label="SNS | BLOG"
            hint=""
            inputProps={{
              name: "blog",
              value: garage.blog,
              onChange: onChangeGarage,
            }}
          />
        </Col>
        <Col sm={6}>
          <Input
            label="SNS | BLOG | 기사"
            hint=""
            inputProps={{
              name: "news",
              value: garage.news,
              onChange: onChangeGarage,
            }}
          />
        </Col>
        <Col sm={6}>
          <Input
            label="SNS | BLOG | 뉴스레터"
            hint=""
            inputProps={{
              name: "newsletter",
              value: garage.newsletter,
              onChange: onChangeGarage,
            }}
          />
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col sm={12}>
          <Input
            label="이용약관"
            hint=""
            inputProps={{
              name: "termUri",
              value: garage.termUri,
              onChange: onChangeGarage,
            }}
          />
        </Col>
        <Col sm={12}>
          <Input
            label="개인정보처리방침"
            hint=""
            inputProps={{
              name: "privacyPolicyUri",
              value: garage.privacyPolicyUri,
              onChange: onChangeGarage,
            }}
          />
        </Col>
      </Row>
    </div>
  );
}
