import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { setData } from "reducers/data";
import update from "immutability-helper";

import { Switch } from "antd";

type Props = { branch: Branch; branchIndex: number };
export default function BranchActive({ branch, branchIndex }: Props) {
	const dispatch = useDispatch();
	const branches = useSelector((state: RootState) => state.data.branches);

	const changeBranchActive = (val: Boolean) => {
		dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: {
            $set: {
              ...branches[branchIndex],
              branchActive: val
            },
          },
        }),
      })
    );
	}

	return (
		<div>
			<h2>
				지점 활성?
				<Switch
					checkedChildren="예"
					unCheckedChildren="아니요"
					defaultChecked={branch.branchActive===true}
					onChange={changeBranchActive}
				/>
			</h2>
		</div>
	);
}
