import Input from "@components/Input";
import { Button, Row, Col, Image } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { setData } from "reducers/data";
import update from "immutability-helper";

type Props = { branch: Branch; branchIndex: number };
export default function BranchImage({ branch, branchIndex }: Props) {
  const dispatch = useDispatch();
  const branches = useSelector((state: RootState) => state.data.branches);

  const onDeleteImage = (imageIndex: number) => {
    dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: {
            $set: {
              ...branches[branchIndex],
              imageUris: [
                ...branches[branchIndex].imageUris.slice(0, imageIndex),
                ...branches[branchIndex].imageUris.slice(imageIndex + 1),
              ],
            },
          },
        }),
      })
    );
  };

  const onChangeImage = (
    e: React.ChangeEvent<HTMLInputElement>,
    imageIndex: number
  ) => {
    dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: {
            $set: {
              ...branches[branchIndex],
              imageUris: [
                ...branches[branchIndex].imageUris.slice(0, imageIndex),
                e.target.value,
                ...branches[branchIndex].imageUris.slice(imageIndex + 1),
              ],
            },
          },
        }),
      })
    );
  };

  const onAddImage = () => {
    dispatch(
      setData({
        branches: update(branches, {
          [branchIndex]: {
            $set: {
              ...branches[branchIndex],
              imageUris: [...branches[branchIndex].imageUris, ""],
            },
          },
        }),
      })
    );
  };
  return (
    <div>
      <h2>
        사진
        <Button size="small" onClick={onAddImage}>
          사진추가
        </Button>
      </h2>
      <Row gutter={10}>
        {branch.imageUris.map((url, index) => {
          return (
            <Col key={index} span={6}>
              <Image
                src={
                  url.startsWith("http") ? url : `https://thegarage.kr${url}`
                }
              />
              <Input
                inputProps={{
                  value: url,
                  onChange: (e) => onChangeImage(e, index),
                  suffix: (
                    <Button onClick={() => onDeleteImage(index)} size={"small"}>
                      제거
                    </Button>
                  ),
                }}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}
