import Search from "@components/Search";
import { firestore } from "@utils/firebase";
import { PageHeader, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";

export default function Space() {
  const [spaces, setSpaces] = useState<any>([]);
  const [results, setResults] = useState<any>([]);
  console.log(results);
  const onSearch = (query: string) => {
    const _results = spaces.filter((item: object) =>
      JSON.stringify(item).includes(query)
    );
    setResults(_results);
  };

  const getData = async () => {
    const { docs } = await firestore()
      .collection("/space")
      .orderBy("createAt", "desc")
      .get();
    const data = docs.map((doc) => doc.data());
    setSpaces(data);
    setResults(data);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns: ColumnsType<any> = [
    { title: "회사명", key: "companyName", dataIndex: "companyName" },
    { title: "담당자명", key: "name", dataIndex: "name" },
    { title: "전화번호/이메일", key: "contact", dataIndex: "contact" },
    { title: "예정 평형", key: "area", dataIndex: "area" },
    { title: "예정 예산", key: "budget", dataIndex: "budget" },
    { title: "생성시간", key: "createAt", dataIndex: "createAt" },
  ];

  return (
    <>
      <PageHeader title="스페이스" />
      <Table
        style={{ wordBreak: "keep-all" }}
        title={() => <Search onSearch={onSearch} />}
        dataSource={results}
        columns={columns}
        rowKey={"createAt"}
      />
    </>
  );
}
