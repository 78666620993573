import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "reducers";
import { Button, Collapse } from "antd";
import Input from "@components/Input";
import Textarea from "@components/Textarea";
import Switch from "@components/Switch";
import { setData } from "reducers/data";
import update from "immutability-helper";
import TextEditor from "@components/TextEditor";
import { Modal } from "antd";

export default function Promotion() {
  const dispatch = useDispatch();
  const promotions = useSelector((state: RootState) => state.data.promotions);
  const [active, setActive] = useState<any>([]);

  const onChangeData = (e: any, index: number) => {
    const { name, value } = e.target;
    dispatch(
      setData({
        promotions: update(promotions, {
          [index]: { $set: { ...promotions[index], [name]: value } },
        }),
      })
    );
  };

  const onChangeSwitch = ({ value, name }: any, index: number) => {
    onChangeData({ target: { value, name } }, index);
  };

  const onClickAdd = () => {
    Modal.warning({
      title:
        "자동 부여된 아이디가 다른 프로모션의 아이디와 중복된 값이 아닌지 확인 후 진행해 주세요.",
    });
    const _promotion: Promotion = {
      promotionId: promotions.length + 1,
      title: "새 프로모션",
      branchText: "",
      imageText: "",
      comment: "",
      content: "",
      limiteFlag: false,
      expiredDate: "",
      thumbnailUri: "",
      imageUri: "",
      link: "",
      value: 0,
      landingValue: 0,
      topBannerValue: 0,
      openFlag: false,
    };
    dispatch(
      setData({
        promotions: [...promotions, _promotion],
      })
    );
  };
  const onClickDelete = (index: number) => {
    dispatch(
      setData({
        promotions: [
          ...promotions.slice(0, index),
          ...promotions.slice(index + 1),
        ],
      })
    );
  };

  return (
    <div>
      <Collapse activeKey={active} onChange={(key) => setActive(key)}>
        {promotions.map((p, index) => (
          <Collapse.Panel header={p.title} key={index}>
            <Input
              label="아이디"
              hint="다른 프로모션과 중복 불가"
              inputProps={{
                name: "promotionId",
                value: p.promotionId,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <Textarea
              label="제목"
              hint="프로모션 제목"
              textareaProps={{
                name: "title",
                value: p.title,
                onChange: (e) => onChangeData(e, index),
                style: { minHeight: "40px" },
              }}
            />
            <Input
              label="지점명"
              hint="ex) 교대점"
              inputProps={{
                name: "branchText",
                value: p.branchText,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <Input
              label="이미지안에 들어갈 텍스트"
              hint=""
              inputProps={{
                name: "imageText",
                value: p.imageText,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <Textarea
              label="짧은 설명"
              hint=""
              textareaProps={{
                name: "comment",
                value: p.comment,
                onChange: (e) => onChangeData(e, index),
                style: { minHeight: "40px" },
              }}
            />
            <Switch
              label="선착순 여부"
              switchProps={{
                checked: p.limiteFlag,
                onChange: (value) =>
                  onChangeSwitch({ value, name: "limiteFlag" }, index),
              }}
            />
            <Input
              label="만료일"
              hint="ex) ~1/31"
              inputProps={{
                name: "expiredDate",
                value: p.expiredDate,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <Input
              label="썸네일 이미지"
              hint=""
              inputProps={{
                name: "thumbnailUri",
                value: p.thumbnailUri,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <Input
              label="이미지"
              hint=""
              inputProps={{
                name: "imageUri",
                value: p.imageUri,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <Input
              label="클릭시 링크"
              hint="외부링크일 경우만 입력 | 미입력 시 /promotion/[promotionId] 로 이동"
              inputProps={{
                name: "link",
                value: p.link,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <Input
              label="정렬값"
              hint="높을수록 앞에 노출 (프로모션 페이지)"
              inputProps={{
                name: "value",
                value: p.value,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <Input
              label="랜딩페이지 정렬값"
              hint="높을수록 앞에 노출 (랜딩페이지 프로모션 부분, 0이면 랜딩페이지에 노출 안함)"
              inputProps={{
                name: "landingValue",
                value: p.landingValue,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <Input
              label="상단배너 정렬값"
              hint="높을수록 앞에 노출 (메뉴-프로모션 버튼 호버시 뜨는 배너안의 리스트, 미입력시 노출 안함)"
              inputProps={{
                name: "topBannerValue",
                value: p.topBannerValue,
                onChange: (e) => onChangeData(e, index),
              }}
            />
            <TextEditor
              id={`${p.promotionId}`}
              label="내용"
              hint=""
              texteditorProps={{
                name: "content",
                value: p.content.replace(/\n/g, "<br />"),
                onChange: (value: any) =>
                  onChangeData({ target: { name: "content", value } }, index),
              }}
            />
            <Switch
              label="보이기"
              hint="활성화 해야 노출됨"
              switchProps={{
                checked: p.openFlag,
                onChange: (value) =>
                  onChangeSwitch({ value, name: "openFlag" }, index),
              }}
            />
            <br />
            <br />
            <Button type="dashed" onClick={() => onClickDelete(index)}>
              프로모션 제거
            </Button>
          </Collapse.Panel>
        ))}
      </Collapse>
      <br />
      <br />
      <Button onClick={onClickAdd}>프로모션 추가</Button>
    </div>
  );
}
